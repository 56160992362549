<template>
  <div class="indentInfo">
    <!-- <van-row type="flex" align="center" class="infoBox">
      <van-col class="infoTitle">供应商</van-col>
      <van-col class="infoName">{{ itemInfo.CompanyName }}</van-col>
    </van-row>20200525总订单(存在多个供应商) -->
    <van-row type="flex" align="center" class="infoBox newInfoBox">
      <van-col class="infoTitle">{{ $t.orderNumber }}</van-col>
      <van-col class="infoNum">{{ itemInfo.OrderNumber }}</van-col>
      <van-col class="infoCopy" @click.stop="onCopy(itemInfo.OrderNumber)">{{
        $t.copy
      }}</van-col>
    </van-row>
    <van-row
      type="flex"
      align="center"
      class="infoBox"
      v-if="itemInfo.PlaceOrderTime"
    >
      <van-col class="infoTitle">{{ $t.timeOfPayment }}</van-col>
      <van-col class="infoName">{{ itemInfo.PlaceOrderTime }}</van-col>
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
import VueClipboard from "vue-clipboard2";
Vue.use(Row).use(Col).use(VueClipboard);

export default {
  name: "IndentInfo",
  props: ["itemInfo"],
  data() {
    return {
      data: "dd",
    };
  },
  methods: {
    onCopy(text) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.clickCopy, {
        text: text,
        alertText: this.$t.copySuccess,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.indentInfo {
  width: 100%;
  font-size: 14px;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid#f6f6f6;
  .infoBox {
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0 30px 0 30px;
    margin: 15px 0;
    .infoTitle {
      width: 25%;
      color: #a7a7a7;
    }
    .infoName {
      width: 75%;
      @include spanNowrap;
      color: #333333;
    }
    .infoNum {
      @include spanNowrap;
      width: 50%;
      color: #333333;
    }
    .infoCopy {
      color: #01aaa3;
      width: 25%;
      text-align: right;
    }
  }
  .newInfoBox {
    margin: 15px 0;
  }
}
</style>
