<template>
  <div class="tabListBox">
    <van-sticky>
      <van-row type="flex" align="bottom" class="tabList">
        <van-col class="tabItem" @click="changeType(-1)">
          <p :class="['tabItemTotal', type == -1 ? 'itemTopAction' : '']">
            {{ $t.allOrders }}
          </p>
          <div
            :class="['itemBorder', type == -1 ? 'itemBorderAction' : '']"
          ></div>
        </van-col>
        <van-col class="tabItem" @click="changeType(20)">
          <p :class="['tabItemTotal', type == 20 ? 'itemTopAction' : '']">
            {{ $t.notDeliverGoods }}
          </p>
          <div
            :class="['itemBorder', type == 20 ? 'itemBorderAction' : '']"
          ></div>
        </van-col>
        <van-col class="tabItem" @click="changeType(30)">
          <p :class="['tabItemTotal', type == 30 ? 'itemTopAction' : '']">
            {{ $t.confiscatedGoods }}
          </p>
          <div
            :class="['itemBorder', type == 30 ? 'itemBorderAction' : '']"
          ></div>
        </van-col>
        <van-col class="tabItem" @click="changeType(1)">
          <p :class="['tabItemTotal', type == 1 ? 'itemTopAction' : '']">
            {{ $t.haveFinished }}
          </p>
          <div
            :class="['itemBorder', type == 1 ? 'itemBorderAction' : '']"
          ></div>
        </van-col>
      </van-row>
    </van-sticky>
  </div>
</template>
<script>
import Vue from "vue";
import { Sticky, Row, Col } from "vant";
Vue.use(Sticky).use(Row).use(Col);
export default {
  name: "TabList",
  props: ["type"],
  data() {
    return {
      data: "CommodityManage",
    };
  },
  methods: {
    changeType(type) {
      this.$emit("changeType", type);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.tabListBox {
  width: 100%;
  font-size: 14px;
  border-bottom: 1px solid #fcfcfc;
  background-color: #ffffff;
  .tabList {
    width: 100%;
    padding-top: 15px;
    text-align: center;
    .tabItem {
      width: 25%;
      text-align: center;
      font-size: 14px;
      color: #333333;
      .tabItemTotal {
        margin: 0;
        padding: 0;
      }
      .itemTopAction {
        color: #01aaa3;
      }
      .itemBorder {
        height: 4px;
        width: 30px;
        margin: 0 auto;
        border-radius: 30px;
        margin-top: 10px;
        opacity: 0;
      }
      .itemBorderAction {
        background-color: #01aaa3;
        opacity: 1;
      }
    }
  }
}
</style>
