<template>
  <div class="receiving">
    <van-row type="flex" align="center" class="receBox">
      <van-col class="receTitle">{{ $t.consignee }}</van-col>
      <van-col class="receUserName">{{ itemInfo.ReceivingName }}</van-col>
      <van-col class="recePhone">{{ itemInfo.PhoneNumber }}</van-col>
    </van-row>
    <van-row type="flex" class="receBox newreceBox">
      <van-col class="receTitle">{{ $t.shippingAddress }}</van-col>
      <van-col class="receP">{{ itemInfo.ReceivingAddress }}</van-col>
      <van-col
        class="receCopy"
        @click.stop="onCopy(itemInfo.ReceivingAddress)"
        >{{ $t.copy }}</van-col
      >
    </van-row>
    <van-row type="flex" align="center" class="receBox">
      <van-col class="receTitle">{{
        $t.totalItems(itemInfo.ProductCount)
      }}</van-col>
      <van-col class="receName">
        <van-row type="flex" align="bottom" class="TotalBox">
          <van-col class="totalNum">
            {{ itemInfo.AllPrice }}
          </van-col>
          <van-col class="totalUnit"> $ </van-col>
          <van-col class="totalName"> {{ $t.total }}： </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
import VueClipboard from "vue-clipboard2";

Vue.use(Row).use(Col).use(VueClipboard);
export default {
  name: "Receiving",
  props: ["itemInfo"],
  data() {
    return {};
  },
  methods: {
    onCopy(text) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.clickCopy, {
        text: text,
        alertText: this.$t.copySuccess,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.receiving {
  width: 100%;
  font-size: 14px;
  padding: 20px 0;
  border-bottom: 1px solid#f6f6f6;
  .receBox {
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0 30px 0 30px;
    .receTitle {
      width: 25%;
      color: #a7a7a7;
    }
    .receUserName {
      max-width: 45%;
      margin-right: 3%;
      @include spanNowrap;
      color: #333333;
    }
    .recePhone {
      min-width: 12%;
      color: #333333;
    }
    .receP {
      // @include spanNowrap;
      flex: 1;
      -webkit-flex: 1;
      color: #333333;
    }
    .receCopy {
      color: #01aaa3;
      width: 13%;
      text-align: right;
    }
    .receName {
      width: 75%;
      text-align: right;
      .TotalBox {
        flex-direction: row-reverse;
        -webkit-flex-direction: row-reverse;
        font-size: 14px;
        color: #333333;
        .totalNum {
          font-size: 20px;
          color: #ff5852;
          font-weight: bold;
          margin-left: 6px;
        }
        .totalUnit {
          font-size: 18px;
          color: #ff5852;
          font-weight: bold;
        }
      }
    }
  }
  .newreceBox {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>
