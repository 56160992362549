<template>
  <div class="indent">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t.noMoreData"
      @load="onLoad"
    >
      <van-sticky>
        <tab-list
          :type="type"
          @changeType="changeType"
          class="indent-top-row"
        ></tab-list>
      </van-sticky>
      <indent-list
        v-if="modelList.length && modelList.length > 0"
        :modelList="modelList"
      ></indent-list>
    </van-list>
  </div>
</template>
<script>
import TabList from "./components/TabList";
import IndentList from "./components/IndentList";

import Vue from "vue";
import { Sticky } from "vant";
import { List } from "vant";
Vue.use(List).use(Sticky);
export default {
  name: "Indent",
  components: {
    TabList,
    IndentList,
  },
  data() {
    return {
      className: "indent-top-row",
      modelList: [],
      sreachValues: "", //搜索关键词
      pageIndex: 1,
      type: this.$route.query.type ? this.$route.query.type : -1,
      isNoData: false,
      isfirst: true,
      loading: false,
      finished: false,
    };
  },
  mounted() {
    window.document.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        document.getElementsByClassName(this.className)[0].style.paddingTop =
          "env(safe-area-inset-top)";
      } else {
        document.getElementsByClassName(this.className)[0].style.paddingTop =
          "0px";
      }
    });
    //首次加载
    this.loadOrderByPage();
  },
  methods: {
    //首次加载
    loadOrderByPage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      if (this.type !== -1) {
        param = {
          PageIndex: this.pageIndex,
          ScreenValues: this.type,
        };
      } else {
        param = {
          PageIndex: this.pageIndex,
        };
      }
      this.$api.order
        .loadOrderByPage(param)
        .then((res) => {
          const data = res.data;
          this.modelList = data;
          if (data === null || data === "" || data.length === 0) {
            this.finished = true;
            this.isfirst = true;
          } else {
            this.finished = false;
            this.isfirst = false;
          }
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.isfirst = false;
          //console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
      //console.log("这是isfirst" + this.isfirst);
    },
    changeType(type) {
      this.type = type;
      this.pageIndex = 1;
      this.isNoData = false;
      this.loading = false;
      this.finished = false;
      this.loadOrderByPage();
    },
    // 加载更多
    onLoad() {
      if (this.isNoData) {
        this.loading = false;
        this.finished = true;
        return;
      }
      this.loading = false;
      this.finished = false;
      if (this.isfirst) return;
      this.loading = true;
      this.finished = false;
      let param = {};
      if (this.type !== -1) {
        param = {
          PageIndex: this.pageIndex + 1,
          ScreenValues: this.type,
        };
      } else {
        param = {
          PageIndex: this.pageIndex + 1,
        };
      }
      this.$api.order
        .loadOrderByPage(param)
        .then((res) => {
          console.log(res);
          const data = res.data;
          if (data.length === 0 || data === "" || data === null) {
            this.loading = false;
            this.finished = true;
            this.isNoData = true;
          } else {
            this.loading = false;
            this.modelList = this.modelList.concat(data);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch((error) => {
          //console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.indent {
  width: 100%;
  // background-color: #f6f6f6;
  .indent-top-row {
    transition: padding-top 0.1s;
  }
}
</style>
